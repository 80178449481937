import silk from '../../assets/platform/fabrics/silk.jpg';
import suit from '../../assets/platform/fabrics/suit.jpg';
import jeans from '../../assets/platform/fabrics/jeans.jpg';
import lace from '../../assets/platform/fabrics/lace.jpg';
import leather from '../../assets/platform/fabrics/leather.jpg';
import stitch from '../../assets/platform/fabrics/stitch.jpg';
import other from '../../assets/platform/fabrics/other.jpg';

const fabrics = [
  { name: 'Soie ou mousseline', slug: 'silk', image: silk },
  { name: 'Tailleur', slug: 'suit', image: suit },
  { name: 'Jeans', slug: 'jeans', image: jeans },
  { name: 'Dentelle', slug: 'lace', image: lace },
  { name: 'Cuir', slug: 'leather', image: leather },
  { name: 'Maille', slug: 'stitch', image: stitch },
  { name: 'Autre (coton, jersey...)', slug: 'other', image: other },
];

export function getFabricName(fabricSlug) {
  if (!fabricSlug) return null;
  const fabric = fabrics.find((fabricIt) => fabricIt.slug === fabricSlug);
  return fabric && fabric.name;
}

export default fabrics;
