import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../Routes';
import {
  colors, mobileThresholdPixels, margins, Title,
} from './styledComponents';
import { pricingInverse } from '../../services/pricing';
import withOrderContext from '../../withOrderContext';
import featureSwitch from '../../services/featureSwitch';
import { Button } from '../home/v2/StyledComponents';
import {
  Button as ButtonV3,
  FormInput as FormInputV2,
} from '../home/v3/styledComponents';
import SlotsSummary from './SlotsSummary';

import iconCalendar from '../../assets/order/iconCalendar.svg';
import iconBasket from '../../assets/platform/summary/iconBasket.svg';
import iconChecked from '../../assets/platform/summary/iconChecked.svg';
import iconNeedleThread from '../../assets/platform/summary/iconNeedleThread.svg';
import iconFabric from '../../assets/platform/summary/iconFabric.svg';
import iconEdit from '../../assets/platform/iconEdit.svg';
import iconGarbage from '../../assets/platform/iconGarbage.svg';
import { getFabricName } from './fabrics';

const Container = styled.div`
  width: ${({ vertical }) => vertical ? 345 : 946}px;
  box-sizing: border-box;
  height: auto;
  margin: ${({ vertical }) => vertical ? '0px' : '80px auto 0px'};
  background-color: ${colors.white};
  padding: ${({ vertical }) => vertical ? '16px 30px 2px' : `${margins.l} ${margins.l} ${margins.s} ${margins.l}`};
  border-top: 8px solid ${colors.blueGrey};
  text-align: left;
  color: ${colors.navy};
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 40px);
    padding: 20px;
    margin: 45px auto;
  }
`;

const StyledTitle = styled(Title)`
  line-height: 24px;
  font-size: 18px;
  ${({ left }) => left && 'text-align: left'}
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: ${({ vertical }) => vertical ? 'column' : 'row'};
  justify-content: space-between;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const SubContainer = styled.div`
  width: ${({ vertical }) => vertical ? 'unset' : '369px'};

  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
  }
`;

const TotalRow = styled.div`
  display: flex;
  flex-direction row;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 20px 0px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ grey }) => grey && `color: ${colors.lightGrey3}; `}

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 10px 0px;
    font-size: 14px;
    line-height: 17px;
  }
`;

const SubTotalRow = styled(TotalRow)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  margin-top: 0px;
  ${({ grey }) => grey && `color: ${colors.lightGrey3}; margin-top: -15px;`}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 17px;
    ${({ grey }) => grey && `color: ${colors.lightGrey3}; margin-top: -5px;`}
  }
`;

const AlterationsRow = styled(TotalRow)`
  justify-content: flex-start;
  align-items: center;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  margin: 2.5px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
  }
`;

const Ul = styled.ul`
  ${({ vertical }) => vertical && 'padding-inline-start: 20px;'};

  @media (max-width: ${mobileThresholdPixels}) {
    padding-inline-start: 40px;
  }
`;

const Li = styled.li`
  list-style-image : url(${(props) => props.icon});
  margin-right: 9px;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    margin-right: 0px;
    margin-left: -20px;
  }
`;

const Icon = styled.img`
  padding: 4px 6px 4px 0px;
`;

const IconEdit = styled.img`
  margin-left: 5px;
  cursor: pointer;
`;

const Line = styled.div`
  border-width: 0.5px;
  border-style: solid;
  border-color: ${colors.lightGrey2};
  margin: ${(props) => props.marginTop || 20}px 0px ${(props) => props.noMarginBottom ? 0 : 20}px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${(props) => props.marginTop || 10}px 0px 10px;
  }
`;

const Span = styled.span`
  color: ${colors.lightGrey4}
`;

const TransformationText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 13px;
  width: 134px;
  max-width: 134px;
  min-width: 134px;
  margin-bottom: 12px;
  padding-left: 0px;
  padding-right: 0px;
  border-width: 2.5px;
`;

const ButtonPromoCode = styled(ButtonV3)`
  width: 111px;
  height: 40px;
  font-size: 18px;
  min-width: unset;
  min-height: unset;
  padding: 3px 0px 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 13px;
    margin: 0px;
    width: 35%;
  }
`;

const PromoCodeForm = styled.div`
  display: flex;
  justify-content: ${(props) => props.vertical ? 'space-between' : 'space-around'};
  flex-wrap: wrap;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  text-align: center;
  margin: ${margins.m} 0px;
`;

const CalendarContainer = styled.div`
  margin-top: 20px;
`;

const Subtitle = styled.section`
  font-family: Gotham;
  font-weight: 600;
  line-height: 24px;
  font-size: 18px;
  text-align: ${(props) => props.left ? 'left' : 'center'};
  color: ${colors.navy};
  ${(props) => props.marginTop && 'margin-top: 79px;'}
`;

const ErrorP = styled.p`
  font-family: Roboto;
  font-size: 15px;
  color: ${colors.red};
  width: 87%;
  margin-top: 15px;
  margin-bottom: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    width: 98%;
  }
`;

const FormInput = styled(FormInputV2)`
  font-size: 14px;
  width: 142px;
  height: 38px;
  margin-top: 0px;
  border-color: ${colors.darkBlueGrey};
  padding-top: 0px;
  padding-bottom: 0px;
  outline: none;
  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(60% - 15px);
  }
`;

const getHasTransformation = (clothes) => clothes
  .reduce((acc, cloth) => acc
    || cloth.typeOfWork === 'transformation'
    || cloth.name.includes('à transformer'), false);

const getShowDiscountButtons = (showDiscountButtonsProp) => showDiscountButtonsProp && (
  featureSwitch('infos_codePromoButtons') || featureSwitch('infos_codePromoButton')
  || featureSwitch('step6_showPromoCodeField'));

const renderTitle = () => (
  <StyledTitle left>
    <img src={iconBasket} alt="Panier" />
    {featureSwitch('retailShopId') ? ' Le devis du client' : ' L‘estimation du client'}
  </StyledTitle>
);

const renderHasTransformation = () => (
  <TransformationText>
    Pour les transformations, le couturier réalisera le devis directement sur place.
    <br />
    C’est lui le mieux placé pour vous recommander les travaux de couture à effectuer :)
  </TransformationText>
);

const renderDates = ({ selectedDates, vertical, promoCode }) => {
  if (featureSwitch('retailShopId')) return null;
  return (
    <SubContainer vertical={vertical}>
      <Ul vertical={vertical}>
        <Li icon={iconChecked}>
          Le devis n’est pas figé, le client pourra modifier la liste de travaux lors du RDV
        </Li>
        <Li icon={iconChecked}>
          Il ne sera pas prélevé avant la validation du devis final avec le couturier
        </Li>
        <Li icon={iconChecked}>
          Il recevra ses vêtements retouchés en 3 à 5 jours après le RDV
        </Li>
      </Ul>

      {selectedDates && !(promoCode?.value) && <Line marginTop={10} />}
      {selectedDates && Object.keys(selectedDates).length > 0
        && (
          <CalendarContainer>
            <Subtitle left>
              <img src={iconCalendar} alt="Calendrier" />
              {' Créneaux sélectionnés'}
            </Subtitle>
            <SlotsSummary selectedDates={selectedDates} noMargin />
          </CalendarContainer>
        )}
    </SubContainer>
  );
};

const renderClothName = ({
  name, canEdit, editingClothIndex, index, clothes, setEditingClothIndex, deleteCloth, total,
}) => (
  <TotalRow marginBottom="10px">
    <span>
      {`${name} à retoucher`}

      {(canEdit
          || (editingClothIndex === null && index !== clothes.length - 1)
          || (editingClothIndex !== null && index !== editingClothIndex))
          && (
            <>
              <IconEdit onClick={() => setEditingClothIndex(index)} src={iconEdit} alt="Éditer" />
              <IconEdit onClick={() => deleteCloth(index)} src={iconGarbage} alt="Supprimer" />
            </>
          )}
    </span>

    {total !== 0 && (
      <span>
        {total}
 €
      </span>
    )}

  </TotalRow>
);

const renderItem = (item) => {
  let pricingInverseName = pricingInverse[item.id].name.replace(' (x1)', '');
  if (item.quantity > 1) { pricingInverseName = `${pricingInverseName} (x${item.quantity})`; }
  return (
    <AlterationsRow key={item.id}>
      <Icon src={iconNeedleThread} alt="Retouche" />
      <span>{pricingInverseName}</span>
    </AlterationsRow>
  );
};

const renderSubTotal = ({ hasUndefinedPrice, totalClothes, hasTransformation }) => {
  if (featureSwitch('retailShopId')) return null;
  if (hasUndefinedPrice) {
    return (
      <TotalRow>
        Sous-Total
        <span>Estimation en RDV</span>
      </TotalRow>
    );
  }
  return (
    <TotalRow>
      Sous-Total
      {' '}
      <span>
        {totalClothes}
 €
        {' '}
        {hasTransformation ? ' + estimation en RDV' : ''}
      </span>
    </TotalRow>
  );
};

const renderDeliveryFee = ({ deliveryFee, hasZipcode, rdv1Location }) => {
  if (featureSwitch('retailShopId')) return null;
  return (
    <>
      <TotalRow>
        Frais de déplacement
        <span>
          {deliveryFee}
 €
        </span>
      </TotalRow>
      {!hasZipcode && rdv1Location !== 'atShop'
        && <SubTotalRow>Les frais de déplacement vous seront précisés en fonction de votre code postal</SubTotalRow>}
      <TotalRow>
        Frais de livraison
        <span>Offerts</span>
      </TotalRow>
    </>
  );
};

const renderPromoCodes = ({
  discountVouchersValue, showDiscountButtons, promoCode, removePromoCode, checkPromoCode, vertical, errorPromoCode, setPromoCodeInput,
  promoCodeInput, secondPromoCode, errorSecondPromoCode, secondPromoCodeInput,
}) => (
  <>
    {discountVouchersValue > 0
      && (
        <TotalRow>
          Crédits parrainage
          <Span>
            -
            {discountVouchersValue}
 €
          </Span>
        </TotalRow>
      )}

    {showDiscountButtons && <Line marginBottom={10} />}

    {showDiscountButtons && !featureSwitch('infos_codePromoAlways') && (
      <TotalRow>Remises</TotalRow>
    )}

    {showDiscountButtons && featureSwitch('infos_codePromoButtons')
      && (
        <ButtonRow>
          {featureSwitch('infos_codePromoButtons').map((promoCodeIt) => {
            const isSelected = promoCode?.value === promoCodeIt.value;
            return (
              <StyledButton
                key={promoCodeIt.code}
                small
                navy={isSelected}
                onClick={() => isSelected ? removePromoCode() : checkPromoCode(promoCodeIt.code)}
              >
                Remise de
                {promoCodeIt.value}
                {' '}
                {promoCodeIt.type === 'PERCENT' ? '%' : '€'}
              </StyledButton>
            );
          })}
        </ButtonRow>
      )}

    {showDiscountButtons && featureSwitch('infos_codePromoButton') && !featureSwitch('infos_codePromoAlways')
      && (
        <ButtonRow>
          <StyledButton
            key={featureSwitch('infos_codePromoButton')}
            small
            navy={promoCode && promoCode.value}
            onClick={() => checkPromoCode(featureSwitch('infos_codePromoButton'))}
          >
            {featureSwitch('infos_codePromoButtonLabel')}
          </StyledButton>
          <StyledButton
            key={featureSwitch('infos_codePromoButton')}
            small
            navy={!(promoCode && promoCode.value)}
            onClick={() => removePromoCode()}
          >
            Pas de remise
          </StyledButton>
        </ButtonRow>
      )}

    {showDiscountButtons && featureSwitch('step6_showPromoCodeField') && !(promoCode && promoCode.value)
      && (
        <div>
          <PromoCodeForm vertical={vertical}>
            <FormInput
              name="promoCode"
              type="text"
              placeholder="Code promo"
              onChange={(event) => setPromoCodeInput(event.target.value)}
            />
            <ButtonPromoCode onClick={() => checkPromoCode(promoCodeInput)}>
              Valider
            </ButtonPromoCode>
            {errorPromoCode && <ErrorP>Ce code promo n‘est pas valide</ErrorP>}
          </PromoCodeForm>
        </div>
      )}

    {promoCode?.value && (
      <TotalRow>
        Remise appliquée
        <Span>
          -
          {promoCode.value}
          {' '}
          {promoCode.unit === 'EUROS' ? '€' : '%'}
        </Span>
      </TotalRow>
    )}

    {featureSwitch('step6_secondPromoCode') && !(secondPromoCode?.value)
      && (
        <div style={{ marginTop: '20px' }}>
          <PromoCodeForm vertical={vertical}>
            <FormInput
              name="secondPromoCode"
              type="text"
              placeholder="Second code promo"
              onChange={(event) => setPromoCodeInput(event.target.value, 2)}
            />
            <ButtonPromoCode onClick={() => checkPromoCode(secondPromoCodeInput, 2)}>
              Valider
            </ButtonPromoCode>
            {errorSecondPromoCode && <ErrorP>Ce code promo n‘est pas valide</ErrorP>}
          </PromoCodeForm>
        </div>
      )}

    {secondPromoCode?.value && (
      <TotalRow>
        Remise appliquée
        <Span>
          -
          {secondPromoCode.value}
          {' '}
          {secondPromoCode.unit === 'EUROS' ? '€' : '%'}
        </Span>
      </TotalRow>
    )}
  </>
);

const renderTotal = ({
  hasUndefinedPrice, totalPaid, discountVouchersValue, hasTransformation,
}) => {
  if (hasUndefinedPrice) {
    return (
      <TotalRow>
        Total estimé
        <span>Estimation en RDV</span>
      </TotalRow>
    );
  }
  return (
    <TotalRow>
      {featureSwitch('retailShopId') ? 'Total' : 'Total estimé'}
      <span>
        {totalPaid - discountVouchersValue > 0
          ? totalPaid - discountVouchersValue : 0}
 €
        {hasTransformation ? ' + estimation en RDV'
          : ''}
      </span>
    </TotalRow>
  );
};

const OrderSummary = ({
  deliveryFee, totalClothes, totalPaid, vertical,
  clothes, selectedDates,
  hasUndefinedPrice, discountVouchersValue, hasZipcode,
  canEdit, orderContext: {
    editingClothIndex, isFastVariant, secondPromoCode, rdv1Location,
  }, setEditingClothIndex, deleteCloth,
  showDiscountButtons: showDiscountButtonsProp, promoCode, removePromoCode, checkPromoCode,
  errorPromoCode, errorSecondPromoCode, setPromoCodeInput, promoCodeInput, secondPromoCodeInput,
}) => {
  const hasTransformation = getHasTransformation(clothes);
  const showDiscountButtons = getShowDiscountButtons(showDiscountButtonsProp);

  return (
    <Container vertical={vertical}>
      {renderTitle()}
      {hasTransformation && renderHasTransformation()}
      <Line marginTop={14} noMarginBottom />
      <OrderContainer vertical={vertical}>
        <SubContainer vertical={vertical}>

          {clothes.map(({
            index, name, total, typeOfWork, items, fabrics,
          }) => (
            <React.Fragment key={index}>
              {renderClothName({
                name, canEdit, editingClothIndex, index, clothes, setEditingClothIndex, deleteCloth, total,
              })}

              {items.map((item) => renderItem(item))}

              {typeOfWork === 'creation' && name !== 'Accessoires '
                && (
                  <AlterationsRow>
                    <Icon src={iconFabric} alt="Matière" />
                    <span>{`Base : ${name.toLowerCase().replace('accessoires (', '').replace(')', '')}`}</span>
                  </AlterationsRow>
                )}

              {fabrics
                .filter((fabric) => getFabricName(fabric))
                .map((fabric) => (
                  <AlterationsRow key={fabric}>
                    <Icon src={iconFabric} alt="Matière" />
                    <span>{getFabricName(fabric)}</span>
                  </AlterationsRow>
                ))}

              {name === 'Robe de mariée'
                && (
                  <AlterationsRow>
                    <Icon src={iconFabric} alt="Matière" />
                    <span>Dentelle et soie / mousseline</span>
                  </AlterationsRow>
                )}
            </React.Fragment>
          ))}

          {isFastVariant
            && (
              <Link
                to={routesMap.Step1.url}
                onClick={() => setEditingClothIndex(0)}
                style={{ color: colors.navy, fontSize: 13, marginTop: '20px' }}
              >
                Cliquer ici pour faire l’estimation de mon client
              </Link>
            )}

          {featureSwitch('retailShopId') ? null : <Line />}

          {renderSubTotal({ hasUndefinedPrice, totalClothes, hasTransformation })}
          {renderDeliveryFee({ deliveryFee, hasZipcode, rdv1Location })}

          {renderPromoCodes({
            discountVouchersValue,
            showDiscountButtons,
            promoCode,
            removePromoCode,
            checkPromoCode,
            vertical,
            errorPromoCode,
            setPromoCodeInput,
            promoCodeInput,
            secondPromoCode,
            errorSecondPromoCode,
            secondPromoCodeInput,
          })}

          <Line />

          {renderTotal({
            hasUndefinedPrice, totalPaid, discountVouchersValue, hasTransformation,
          })}
        </SubContainer>

        {renderDates({ selectedDates, vertical, promoCode })}
      </OrderContainer>
    </Container>
  );
};

OrderSummary.propTypes = {
  deliveryFee: PropTypes.number.isRequired,
  totalClothes: PropTypes.number.isRequired,
  totalPaid: PropTypes.number.isRequired,
  clothes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string,
    })),
    typeOfWork: PropTypes.string,
    total: PropTypes.number.isRequired,
    fabrics: PropTypes.arrayOf(PropTypes.string),
  })),
  selectedDates: PropTypes.shape({}),
  orderContext: PropTypes.shape({
    editingClothIndex: PropTypes.number,
    isFastVariant: PropTypes.bool,
    secondPromoCode: PropTypes.shape({
      value: PropTypes.number,
      unit: PropTypes.string,
    }),
    rdv1Location: PropTypes.string,
  }).isRequired,
  setEditingClothIndex: PropTypes.func.isRequired,
  deleteCloth: PropTypes.func.isRequired,
  vertical: PropTypes.bool,
  hasUndefinedPrice: PropTypes.bool,
  discountVouchersValue: PropTypes.number.isRequired,
  hasZipcode: PropTypes.bool,
  canEdit: PropTypes.bool,
  showDiscountButtons: PropTypes.bool,
  promoCode: PropTypes.shape({
    value: PropTypes.number,
    unit: PropTypes.string,
  }),
  checkPromoCode: PropTypes.func.isRequired,
  removePromoCode: PropTypes.func.isRequired,
  errorPromoCode: PropTypes.bool,
  errorSecondPromoCode: PropTypes.bool,
  setPromoCodeInput: PropTypes.func,
  promoCodeInput: PropTypes.string,
  secondPromoCodeInput: PropTypes.string,
};

OrderSummary.defaultProps = {
  clothes: [],
  selectedDates: null,
  vertical: false,
  hasUndefinedPrice: false,
  hasZipcode: false,
  canEdit: false,
  showDiscountButtons: false,
  promoCode: null,
  errorPromoCode: false,
  errorSecondPromoCode: false,
  setPromoCodeInput() {},
  promoCodeInput: '',
  secondPromoCodeInput: '',
};

export default withOrderContext(OrderSummary);
